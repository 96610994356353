.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  opacity: 0;
  padding: 30px 32px 10px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  opacity: 1;
  transition: all .5s;

  &_hidden {
    opacity: 0;
  }
}
.wrap1 {
  flex: 1 0;
}
.wrap2 {
  flex: 1.4 0;
}
// and (orientation: landscape)
@media (max-width: 991px) and (min-width: 560px) {
  .header {
    padding: 2.7% 1.5%;
  }
  .wrap2 {
    flex: 0 0;
  }
}

@media (max-width: 560px) {
  .wrap1,
  .wrap2 {
    display: none;
  }
  .header {
    padding: 2.7% 1.5%;
  }
  .burger_wrap {
    order: 1;
  }
  .logo_wrap {
    order: 2;
  }
  .leng_wrap {
    order: 3;
  }
}
