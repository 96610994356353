

.container {

  width: 100%;
  max-width: 306px;
  height: 100%;
  background-color: #fff;
  color: #363636;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 666;
  transition: all 0.3s ease;
  transform: translate(100%, 0);
  padding: 3vmin 1vmin;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.active {
  transform: translate(0%, 0);
}

.burger_cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vmin;
}
.nav {
  width: 100%;
  flex: 1 0;
}
.copy {
  text-align: center;
  font-size: 1em;
  letter-spacing: 0.9px;
  color: inherit;
  & span {
    font-weight: 500;
  }
}
.license {
  margin-bottom: 4vmin;
  width: 100%;
  &_title {
    max-width: 190px;
    width: 100%;
    margin: 0 auto 0.8vmin;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
  }
  & img {
    display: block;
    margin: 0 auto;
    max-width: 296px;
    width: 100%;
    min-width: 250px;
  }
}

.social {
  width: 100%;
  max-width: 210px;
  flex: 0.4 0;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 1vmin;
}
@media (max-width: 560px) {
  .container {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}



.viewer_privacy {
  a {
    text-decoration: none;
    color: black;
    font-weight: 500;
  }
  text-align: center;
  font-size: .7em;
  margin-bottom: 1em;
}

