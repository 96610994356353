.logo {
  max-width: 295px;
  width: 28vmin;
  min-width: 180px;
  & img {
    width: 50%;
  }
}
// and (orientation: landscape)
@media (max-width: 991px) and (min-width: 560px) {
  .logo {
    max-width: 91px;
    width: 8.5vmin;
    height: 8.5vmin;
    // border-radius: 50%;
    background-color: #f8febf;
    overflow: hidden;
    padding-top: 15%;
    padding-left: 5%;
    min-width: 40px;
    min-height: 40px;
    & img {
      width: 105%;
    }
  }
}

@media (max-width: 560px) {
  .logo {
    width: 54.5%;
    height: auto;
    min-height: 60px;
    border-radius: 30px;
    padding: 0 10px;
    background-color: #fff;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    & img {
      width: 100%;
    }
  }
}
