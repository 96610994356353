.fluid {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
}

.close_video {
  width: 5vw;
  height: 5vw;
  top: .5em;
  right: .5em;
  z-index: 5;
  position: absolute;
  border-radius: 50%;
  background: #417c9a;

  &:before, &:after {
    content: '';
    display: block;
    top: 50%;
    left: 50%;
    position: absolute;
    background: white;
    width: 65%;
    height: 3px;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
    
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
    
  }
}

.container {
  width: 95%;
  max-width: 990px;
  max-height: 666px;
  height: 66vmin;
  min-height: 400px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  left: 0;
  top: 25%;
  padding: 40px 31px 20px 20px;

}

.no_padding {
  padding: 0!important;
  background-color: transparent!important;
}

.close {
  font-weight: 800;
  border: none;
  background-color: #fff;
  color: #363636;
  position: absolute;
  z-index: 5;
  right: 3%;
  top: 15px;
  font-size: 1.1em;
  cursor: pointer;
}
.content {
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) and (orientation: landscape) {
  .fluid {
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }
  .container {
    top: 0;
    left: 0;
    flex: 0.75 0;
    border-radius: 8px;
    padding: 10px 5px 10px 10px;
    margin-bottom: 10px;
    min-height: auto;
  }
  .close {
    right: 0%;
    top: -30px;
    font-size: 15px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
  }
}

@media (max-width: 580px) {
  .fluid {
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }
  .container {
    top: 0;
    left: 0;
    flex: 0.75 0;
    border-radius: 8px;
    padding: 10px 5px 10px 10px;
    margin-bottom: 10px;
    min-height: auto;
  }
  .close {
    right: 0%;
    top: -30px;
    font-size: 15px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
  }
}
