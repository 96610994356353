.root {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.wrapper {
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  display: flex;
  min-height: 100%;
}
.title {
  margin-bottom: 1em;
  text-align: center;
  &>* {
    font-weight: 500;
    line-height: 1.3em;
  }
}

.logo {
  margin-bottom: 1em;
}

.list {
  width: 100%;
  max-width: 540px;
  flex-direction: column;
  display: flex;
}

.question {
  margin: .3em 0;
  background-color: #ececec;
  padding: 1.3em;
  border-radius: 10px;
  text-indent: -1em;
}

.answers {
  display: flex;
  margin-left: -1em;
}

.wrap {
  margin-bottom: 3.3125em;
}

.answer {
  border-radius: 10px;
  margin-left: 1em;
  cursor: pointer;
  background-color: black;
  color: white ;
  padding: 1.375em 1.7em;
  font-weight: 500;
  &_return {
    margin: 0;
  }
}

.yes {
  opacity: .5;
}

.yes_content {
  align-items: center;
  justify-content: center;
  display: flex;

  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.yes_title {
  text-align: center;
  font-weight: 500;
  flex-direction: column;
  display: flex;
  h3 {
    font-size: 1.2em;
  }
  span {
    margin-top: 1em;
  }
}

.return {
  margin-top: 2em;
}

.waitperCheck {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}
.waitper{
  display: flex;
  max-width: 550px;
}
.waitperElem {
  margin: 10px;
}