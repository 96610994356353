// @font-face {
//   font-family: "KozGoPro";
//   src: url("./fonts/KozGoPro/KozGoPro-Regular.otf");
//   font-weight: 400;
//   font-style: normal;
//   font-stretch: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Vegur-R";
//   src: url("./fonts/KozGoPro/Vegur-R.otf");
//   font-weight: 400;
//   font-style: normal;
//   font-stretch: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica Neue"), url("./fonts/Helvetica/HelveticaNeueLight.ttf");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica Neue"), url("./fonts/Helvetica/HelveticaNeue.ttf");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica Neue"), url("./fonts/Helvetica/HelveticaNeueMedium.ttf");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica Neue"), url("./fonts/Helvetica/HelveticaNeueBold.ttf");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
}
body {
  font-size: 16px;
  line-height: 1;
  font-family: "Helvetica", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.center {
  text-align: center;
}
a {
  width: 100%;
  display: block;
}
// ==============================================START full page css
#fp-nav {
  top: 100% !important;
  transform: translate(0, -50%) !important;
}
#fp-nav ul li {
  border: 2px solid #363636 !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50%;
  margin: 4vmin 10px !important;
  & a span {
    width: 100%;
    height: 100%;
    background-color: transparent !important;
  }
}
#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: #363636 !important;
  margin: -7px 0 0 -7px !important;
  width: 13px !important;
  height: 13px !important;
}
@media (max-width: 991px) and (min-width: 560px) and (orientation: landscape) {
  #fp-nav {
    top: 100% !important;
    transform: translate(-50%, -50%) !important;
  }
  #fp-nav ul li {
    border: 1px solid #363636 !important;
    width: 2vmin !important;
    height: 2vmin !important;
    border-radius: 50%;
    margin: 7vmin 10px !important;
    & a span {
      width: 100%;
      height: 100%;
      background-color: transparent !important;
    }
  }
  #fp-nav ul li:hover a.active span,
  #fp-nav ul li a.active span,
  .fp-slidesNav ul li:hover a.active span,
  .fp-slidesNav ul li a.active span {
    background-color: #363636 !important;
    margin: -1vmin 0 0 -1vmin !important;
    width: 2vmin !important;
    height: 2vmin !important;
  }
}
@media (max-width: 560px) {
  #fp-nav {
    top: 100% !important;
    left: 50% !important;
    transform: translate(-50%, -12%) rotate(-90deg) !important;
  }
  #fp-nav ul li {
    border: 1px solid #363636 !important;
    width: 2vmin !important;
    height: 2vmin !important;
    border-radius: 50%;
    margin: 7vmin 10px !important;
    & a span {
      width: 100%;
      height: 100%;
      background-color: transparent !important;
    }
  }
  #fp-nav ul li:hover a.active span,
  #fp-nav ul li a.active span,
  .fp-slidesNav ul li:hover a.active span,
  .fp-slidesNav ul li a.active span {
    background-color: #363636 !important;
    margin: -1vmin 0 0 -1vmin !important;
    width: 2vmin !important;
    height: 2vmin !important;
  }
}

// ======================================= fade effect

.fullpage-wrapper {
  width: 100% !important;
  transform: none !important;
}

.fp-section {
  width: 100% !important;
  position: absolute !important;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  transition: all 0.4s ease-in-out;
}

.fp-section.active {
  visibility: visible;
  opacity: 1;
  z-index: 10;
}
// ==============================================END full page css

.nav-link {
  max-width: 284px;
  width: 100%;
  max-height: 60px;
  height: 6vmin;
  min-height: 2em;
  text-decoration: none;
  border: 1px solid #363636;
  text-transform: uppercase;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #363636;
  font-weight: 500;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 1vmin;
  }
}
.nav-link-active {
  color: #fff;
  background-color: #417c9a;
  border-color: transparent;
}

.fp-tableCell {
  vertical-align: top !important;
}
// .active {
//   background: linear-gradient(to bottom, #a4a578 0%, #e3fbc9 30%, #aff2ee 100%);
// }
.relativeCont {
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 0;
  pointer-events: none;
  z-index: 999;
}

@media (max-width: 400px) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #363636 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.slick-slide {
  opacity: 0.5;
  transition: opacity 0.2s ease;
}
.slick-current {
  opacity: 1;
}

.content_container {
  flex: 1 0;
}
a {
  text-decoration: none;
}

.node-enter {
  opacity: 0;
}
.node-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.node-exit {
  opacity: 1;
}
.node-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
