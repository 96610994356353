.burgerCont {
  max-width: 91px;
  width: 8.5vmin;
  min-width: 50px;
  max-height: 91px;
  height: 8.5vmin;
  min-height: 50px;
  border: 2px solid #363636;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.burger {
  width: 53%;
  height: 36.3%;
  position: relative;
}
.burger__middle {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 15%;
  background: #363636;
  opacity: 0.5;
  border-radius: 2.5px;
  transform: translate(0, -50%);
  transition: all 0.1s ease-in;
}
.burger__top {
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 15%;
  background: #363636;
  opacity: 0.5;
  border-radius: 2.5px;
  transform: translate(0, -50%);
  transition: all 0.2s ease-out;
}
.burger__bottom {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 15%;
  background: #363636;
  opacity: 0.5;
  border-radius: 2.5px;
  transform: translate(0, -50%);
  transition: all 0.2s ease-out;
}

.active {
  & .burger__middle {
    opacity: 0;
  }
  & .burger__top {
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
    opacity: 1;
  }
  & .burger__bottom {
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg);
    opacity: 1;
  }
}
// and (orientation: landscape)
@media (max-width: 991px) and (min-width: 560px) {
  .burgerCont {
    background-color: #fff;
    border: none;
    // width: 8%;
    // height: 14%;
    min-width: 40px;
    min-height: 40px;
  }
  .burger__bottom,
  .burger__top,
  .burger__middle {
    opacity: 1;
    background-color: #587b97;
  }
}

@media (max-width: 560px) {
  .burgerCont {
    background-color: #fff;
    border: none;
    min-width: 60px;
    min-height: 60px;
  }

  .burger__bottom,
  .burger__top,
  .burger__middle {
    opacity: 1;
    background-color: #587b97;
  }
}
