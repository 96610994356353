.input {
  width: 100%;
  border: none;
  font-size: 1.7vmin;
  max-height: 62px;
  font-family: "Helvetica", sans-serif !important;
  height: 6vmin;
  min-height: 30px;
  color: #fff;
  border-radius: 12px;
  background: #363636;
  font-weight: 500;
  opacity: 0.75;
  padding: 0 1.8vmin;
  transition: all 0.3s ease;
  &:not(:placeholder-shown) {
    opacity: 1;
  }
  &:placeholder-shown {
    color: #fff;
  }
  &:hover,
  &:focus {
    opacity: 1;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &::-webkit-input-placeholder {
    color: #fff;
    font-weight: 500;
  }
  &::-moz-placeholder {
    color: #fff;
    font-weight: 500;
  } /*Firefox 19+*/
  &:-moz-placeholder {
    color: #fff;
    font-weight: 500;
  } /*Firefox 18-*/
  &:-ms-input-placeholder {
    color: #fff;
    font-weight: 500;
  }
}
.error {
  border: 4px solid #f00;
}
.center {
  text-align: center;
}

@media (max-width: 900px) and (min-width: 560px) and(orientation: landscape) {
  .input {
    font-size: 13px;
    height: 11vmin;
    color: #fff;
    border-radius: 6px;
    &:not(:last-child) {
      margin-bottom: 1vmin;
    }
  }
  .error {
    border: 2px solid #f00;
  }
}

@media (max-width: 560px) {
  .input {
    font-size: 3vmin;
    height: 11vmin;
    color: #fff;
    border-radius: 6px;
    &:not(:last-child) {
      margin-bottom: 1vmin;
    }
  }
  .error {
    border: 2px solid #f00;
  }
}
