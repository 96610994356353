.container {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5.4vmin;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition-delay: 0.1s;
  transition: all 0.5s ease;
  pointer-events: none;
  &_active {
    opacity: 1;
    transition: all 0.3s ease;
    transition-delay: 1s;
    z-index: 999;
  }
}
.form {
  pointer-events: auto;

  width: 100%;
  max-width: 595px;
  margin: 0 auto;
  border-radius: 12px;
  position: relative;
  background: linear-gradient(
      84deg,
      rgba(255, 255, 222, 0.8),
      rgba(254, 255, 183, 0.1) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    );
  display: flex;
  padding: 1.7vmin 1vmin 2vmin;
  justify-content: space-between;
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease;
  flex-wrap: wrap;
  &__item {
    width: 50%;
    padding: 0 2.3vmin;
  }
  & * {
    opacity: 0;
    transition: all 0.5s ease;
  }
  &_active {
    opacity: 1;
    transition-delay: 1.5s;
    & * {
      opacity: 1;
      transition-delay: 1.7s;
    }
  }
}

.title {
  text-transform: uppercase;
  font-size: 1.7vmin;
  color: #363636;
  font-weight: 500;
  margin: 1vmin;
}
.dateCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &__min {
    width: 26.6%;
  }
  &__max {
    width: 36.17%;
  }
}
.btn {
  width: 100%;
  max-height: 62px;
  width: 100%;
  align-self: flex-end;
  height: 5vmin;
  text-align: center;
  font-size: 2.3vmin;
  font-weight: 500;
  border-radius: 12px;
  background: #fff;
  color: #363636;
  text-transform: uppercase;
  border: none;
  transition: all 0.3s ease;
  // &:hover {
  //   background: #417c9a;
  //   color: #fff;
  // }
}
// ============================must be update
.trav_cont {
  width: 100%;
  max-width: 595px;
  margin: 0 auto;
  margin-top: 29px;
  max-height: 40vmin;
  overflow-y: auto;
  position: relative;
  z-index: 99;
  pointer-events: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    background-color: #999;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #363636;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #999;
  }
  &::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #666;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-corner {
    background-color: #999;
  }
  &::-webkit-resizer {
    background-color: #666;
  }
}

.error {
  width: 100%;
  max-width: 595px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.75);
  text-align: center;
  padding: 1.9vmin 2vmin 20px;
  margin-bottom: -15px;
  border-radius: 12px 12px 0 0;
  &__item {
    text-transform: uppercase;
    font-size: 1.7vmin;
    font-weight: 500;
    color: #f00;
    display: block;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.cover {
  width: 100%;
}

.fullBtn {
  background: rgba(65, 124, 154, 0.75);
  color: #fff;
  &:hover {
    background: rgba(65, 124, 154, 1);
  }
}

.emptyBtn {
  background: #fff;
  color: #363636;
}

@media (max-width: 900px) and (min-width: 560px) and (orientation: landscape) {
  .container {
    padding-bottom: 2vmin;
  }
  .form {
    width: 100%;
    max-width: 55%;
  }
  .title {
    font-size: 13px;
  }
  .error {
    max-width: 55%;
    margin: 0 auto -10px;
    &__item {
      text-transform: uppercase;
      font-size: 13px;
    }
  }
  .btn {
    height: 11vmin;
    text-align: center;
    font-size: 4vmin;
    border-radius: 6px;
  }
  .trav_cont {
    width: 100%;
    max-width: 55%;
    margin: 0 auto;
    margin-top: 2vmin;
    max-height: 40vmin;
    overflow-y: auto;
  }
}
@media (max-width: 700px) and (min-width: 560px) and (orientation: landscape) {
  .title {
    font-size: 10px;
  }
  .trav_cont {
    max-height: 35vmin;
  }
}
@media (max-width: 560px) {
  .form {
    width: 100%;
    max-width: 90%;
  }
  .title {
    font-size: 2.5vmin;
  }
  .error {
    width: 100%;
    max-width: 90%;
    margin: 0 auto -10px;
    &__item {
      text-transform: uppercase;
      font-size: 2.5vmin;
    }
  }
  .btn {
    height: 11vmin;
    text-align: center;
    font-size: 4vmin;
    border-radius: 6px;
  }
  .trav_cont {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 2vmin;
    max-height: 70vmin;
    overflow-y: auto;
  }
}

@media (max-width: 374px) {
  .container {
    padding-bottom: 8vmin;
  }
}

.genders {
  display: flex;
  justify-content: space-between;
}

.gender_option {
  width: calc(50% - .2em);
  background: rgba(54, 54, 54, 0.75);
  padding: 1em;
  border-radius: .7em;
  color: white;
  display: flex;
  justify-content: center;
  transition: all .1s;
  cursor: pointer;
  align-items: center;

  
  &_selected {
    background-color: rgba(54, 54, 54, 1);
  }
}