.container {
  width: 100%;
  border-radius: 12px;
  background: rgba(54, 54, 54, 0.75);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  height: 6vmin;
  font-size: 1.7vmin;
  position: relative;
  z-index: 9;
  cursor: pointer;
  transition: all 0.3s ease !important;
  font-weight: 500;
  padding: 0 10px;
  &:hover {
    background: rgb(78, 78, 78);
  }
  &_min {
    height: 5vmin;
    min-height: 40px;
    max-height: 50px;
    border-radius: 10px;
  }
  & * {
    pointer-events: none;
  }
}
.radio {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;

  border: 0.4vmin solid rgba(54, 54, 54, 1);
  border-radius: 0.5vmin;
  margin-right: 0.5vmin;
  background: rgba(54, 54, 54, 1);
  transition: all 0.2s ease;
  pointer-events: none;
}

.active {
  background: rgba(54, 54, 54, 1);
  & .radio {
    background-color: #fff;
    border: 0.4vmin solid #6f6f6f;
  }
}
@media (max-width: 560px) {
  .container {
    font-size: 2.7vmin;
  }
}