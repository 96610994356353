.lengContainer {
  width: 271px;
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 999;
  align-items: center;
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    // font-family: "KozGoPro";
    // font-family: "Vegur-R";
    text-transform: uppercase;
    font-size: 16px;
    line-height: 2.25;
    color: #363636;
    opacity: 0.6;
    transition: all 0.3s ease;
    & img {
      width: 41px;
      margin-right: 5px;
      pointer-events: none;
    }
    & span {
      pointer-events: none;
    }
  }
  &__line {
    width: 2px;
    height: 46px;
    object-fit: contain;
    opacity: 0.5;
    background-color: #363636;
  }
  &__active {
    opacity: 1;
  }
}
.leng_m {
  position: relative;
  cursor: pointer;
}
.leng_m_item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12%;
  overflow: hidden;
  & img {
    width: 55%;
    pointer-events: none;
  }
  & span {
    text-transform: uppercase;
    font-size: 50%;
    margin-top: -2px;
    pointer-events: none;
  }
}
.leng_m_ch {
  position: absolute;
  width: 100%;
  background-color: #fff;
  left: 0;
  top: 0;
  right: 0;
  padding-bottom: 10px;
  border-radius: 30px;
}
// and (orientation: landscape)
@media (max-width: 991px) and (min-width: 560px) {
  .leng_m {
    background-color: #fff;
    max-width: 91px;
    width: 8.5vmin;
    min-width: 40px;
    max-height: 91px;
    height: 8.5vmin;
    min-height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

@media (max-width: 560px) {
  .leng_m {
    background-color: #fff;
    max-width: 91px;
    width: 8.5vmin;
    min-width: 60px;
    max-height: 91px;
    height: 8.5vmin;
    min-height: 60px;
    border-radius: 50%;
  }
}
