.modal_logo_container {
  justify-content: center;
  align-items: center;
  display: flex;
}
.list {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.traveler {
  margin: 80px 0px;
  display: flex;
  justify-content: center;
}
.travelerName {
  font-weight: bold;
  margin-right: 10px;
}
.btn {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 2.2vmin;
  color: #fff;
  opacity: 0.75;
  margin: 0 10px;
  border: none;
  box-shadow: none;
  // padding: 15px 0 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 1.1vmin;
  overflow: hidden;
  position: relative;
  max-width: 164px;
  width: 16vmin;
  max-height: 62px;
  height: 6vmin;
  &:hover {
    opacity: 1;
  }
  &__prev {
    padding-left: 2.77vmin;
    padding-right: 1.94vmin;
    background: url(./../../../asset/prev.svg) left center no-repeat;
    background-size: 100%;
  }
  &__next {
    padding-left: 1.94vmin;
    padding-right: 2.77vmin;
    background: url(./../../../asset/continue.svg) right center no-repeat;
    background-size: 100%;
  }

  &__active {
    background: black;
  }
}

@media (max-width: 900px) and (min-width: 560px) and(orientation: landscape) {
  .btn {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 4.5vmin;
    color: #fff;
    opacity: 0.5;
    border: none;
    padding: 1.94vmin 0 2vmin;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 1.1vmin;
    overflow: hidden;
    position: relative;
    width: 34vmin;
    height: 12vmin;
    z-index: 9999;
  }
}
@media (max-width: 560px) {
  .btn {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 4.5vmin;
    color: #fff;
    opacity: 0.5;
    border: none;
    // padding: 1.94vmin 0 2vmin;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 1.1vmin;
    overflow: hidden;
    position: relative;
    min-width: 32vmin;
    min-height: 40px;
    height: 9vmin;
  }
}
.contentsss {
  flex: 1 0;
  max-width: 545px;
  width: 95%;
  font-size: 16px;
  color: #363636;
  display: flex;
  flex-direction: column;
  &_futter {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.question {
  margin: 0.3em 0;
  background-color: #ececec;
  padding: 1.3em;
  border-radius: 10px;
  text-indent: -0.3em;
  text-align: justify;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.answers {
  display: flex;
  margin-left: -1em;
}

.wrap {
  margin-bottom: 3.3125em;
}

.answer {
  border-radius: 10px;
  margin-left: 1em;
  cursor: pointer;
  background-color: black;
  color: white;
  padding: 1.375em 1.7em;
  font-weight: 500;
  &_return {
    margin: 0;
  }
}
.subtitle {
  text-align: center;
  margin: 20px 10px;
  font-size: 17px;
}
.policy {
  font-size: 16px;
  padding: 0.5em 0.7em 0;
  width: 20%;
  &__container {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 4);
    position: relative;
    text-align: center;
  }
  &_logo {
    display: block;
    max-width: 50%;
    margin: 0 auto;
  }
  &_price {
    padding: 5px 5px 15px;
    font-size: 2em;
    font-weight: 300;
    color: #417c9a;
  }
  &__btn {
    width: 90%;
    display: block;
    margin: 0 auto -1px;
    max-height: 61px;
    height: 6vmin;
    min-height: 35px;
    text-transform: uppercase;
    border-radius: 0 0 12px 12px;
    background-color: #417c9a;
    color: #fff;
    font-size: 1em;
    border: none;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}
@media (max-width: 580px) {
  .policy {
    margin-top: 45px;
    width: 100%;
  }
}
.logo_container {
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 0px;
}
.inpt_container {
  width: 100%;
  padding: 2.5px 5px;
  *[data-name="coverage"] {
    text-align: left !important;
    padding: 10px;
    justify-content: flex-start;
  }
  *[data-name="deductible"] {
    text-align: left !important;
    padding: 10px;
    justify-content: flex-start;
  }
  *[data-name="optionalMedical"] {
    text-align: left !important;
    padding: 10px !important;
    justify-content: flex-start;
  }
}
.content {
  padding: 2.5px 5px;
  & h4 {
    font-size: 1.1em;
    text-align: center;
    text-transform: uppercase;
    padding: 7px 5px 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.text_block {
  width: 100%;
  background-color: #363636;
  margin-bottom: 5px;
  color: #fff;
  padding: 10px 5px;
  border-radius: 8px;
  font-weight: 300;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mod_btn {
  width: 1.1em;
  min-width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
  border: none;
  margin: 0 5px;
  background-color: #363636;
  color: #fff;
  text-align: center;
  font-style: italic;
  font-size: 0.7em;
  cursor: pointer;
}
.inpt_container_multiple {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.item_multiple {
  width: 50%;
  padding: 2.5px;
}

.modal_content {
  width: 100%;
  padding-right: 30px;
}
.modal_content_item {
  display: flex;
  font-size: 1.1em;
  color: #363636;
  line-height: 1.2;
  font-weight: 500;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &:nth-child(2n-1) {
    background-color: #ccc;
  }
}
.modal_content_benefit {
  width: 35%;
  padding: 10px;
  border-right: 3px solid #fff;
  &_tugo {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.modal_content_details {
  width: 65%;
  padding: 10px;
}
.modal_content_list {
  width: 65%;
  padding: 10px;
  list-style-type: none;
  display: flex;

  flex-direction: column;
  & li {
    margin-bottom: 10px;
  }
}
.modal_content_listTravel {
  width: 65%;
  padding: 10px;
  list-style-type: none;
  display: flex;

  flex-direction: column;
  & li {
    margin-bottom: 10px;
  }
  & li:nth-child(odd) {
    margin-bottom: 10px;
    background-color: rgb(236, 236, 236);
    // opacity: 0.1;
  }
}

.modal_content_title {
  display: flex;
  text-transform: uppercase;
  color: #363636;
  font-size: 1.1em;
  font-weight: 600;
}
.modal_container {
  // border: 1px solid red;
  font-family: "Helvetica", "Helvetica Neue", sans-serif;
}
.modal_subm_title {
  font-size: 1.1em;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  margin-bottom: 15px;
}

.modal_subm_name_container {
  max-width: 240px;
  padding: 10px 0;
  border-radius: 12px;
  color: #fff;
  background-color: #363636;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & div {
    padding: 3px 0;
  }
}

.link_to_more {
  margin-top: 3em;
  text-align: center;
  width: 100%;

  a {
    display: inline;
    color: black;
    font-weight: 600;
  }
}

.modal_subm_text_container {
  max-width: 536px;
  width: 90%;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1.4;
  margin: 0 auto 25px;
}

.destination_modal_title {
  padding-right: 5em;
  margin-top: 2em;
}

.modal_subm_btn_container {
  max-width: 250px;
  margin: 0 auto 35px;
  display: flex;
  justify-content: space-between;
}
.modal_subm_btn {
  background-color: #666;
  color: #fff;
  text-transform: uppercase;
  border-radius: 12px;
  width: 46%;
  max-height: 60px;
  height: 6vmin;
  min-height: 35px;
  border: none;
  cursor: pointer;
}

.modal_subm_select_container {
  margin: 0 auto 30px;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
}

.modal_subm_select_box {
  width: 48%;
  & h4 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 19px;
    font-size: 1.1em;
    font-weight: 800;
  }
}

.modal_subm_continue {
  display: block;
  max-width: 240px;
  width: 90%;
  max-height: 60px;
  height: 6vmin;
  min-height: 35px;
  border-radius: 12px;
  color: #fff;
  background-color: #417c9a;
  margin: 0 auto;
  border: none;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 500;
  cursor: pointer;
}

// ====================
.tabs_btns_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 30px;
  width: 98%;
}
.tugo_tab_btn {
  margin: 0 5px;
  flex: 1;
  background: rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  font-weight: 300;
  max-height: 38px;
  height: 3vmin;
  min-height: 30px;
  font-size: 1.1em;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #417c9a;
  }
  &_active {
    font-weight: 500;
    background: #417c9a;
  }
}
.tugo_tab_cont {
  transition: all 0.5s ease-in;
  width: 98%;
  position: absolute;
}
.tugo_tab_info {
  width: 98%;
  padding: 0 10px;
  line-height: 1.3;
  font-size: 1em;
  font-weight: 500;
  color: #363636;
  margin-bottom: 30px;
  & p {
    margin-bottom: 10px;
  }
}

.check_cont {
  width: 200px;
  margin: 30px auto 10px;
}

.ppp {
  max-width: 300px;
  text-align: center;
}
.ppp_l {
  max-width: 300px;
  text-align: left;
}

.questText {
  text-align: center;
  padding: 20px 4%;
}

.questText_flex {
  display: flex;
  justify-content: space-between;
  max-width: 670px;
  width: 90%;
  margin: 0 auto;
  & ul {
    list-style-type: none;
    padding: 15px 0;
    & li {
      padding: 10px 0;
    }
  }
  & ul:first-child {
    width: 45%;
    text-align: left;
  }
  & ul:last-child {
    width: 55%;
    text-align: left;
  }
}

@media (max-width: 900px) and (orientation: landscape) {
  .policy {
    margin-top: 10px;
    &_price {
      font-size: 1.5em;
      padding-bottom: 10px;
      width: 20%;
      text-align: center;
    }
    &__btn {
      font-size: 0.9em;
    }
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }
  .logo_container {
    min-height: 45px;
    width: 50%;
    & img {
      max-width: 70%;
    }
  }
  .content {
    width: 100%;
    & h4 {
      font-size: 0.9em;
    }
  }

  .modal_subm_title {
    font-size: 0.9em;
  }
  .modal_subm_text_container {
    font-size: 0.9em;
  }
  .modal_subm_select_box {
    & h4 {
      font-size: 0.8em;
    }
  }
  .modal_subm_continue {
    font-size: 0.9em;
  }
  .tabs_btns_cont {
    & button {
      font-size: 0.8em;
    }
  }
  .tugo_tab_info {
    font-size: 0.8em;
  }
  .policy_logo_mod {
    max-width: 21%;
    margin-bottom: 5px;
  }
  .modal_content {
    padding-right: 10px;
    &_title {
      font-size: 0.8em;
    }
    &_benefits {
      padding: 3px;
    }
    &_item {
      font-size: 0.8em;
    }
  }
}

@media (max-width: 580px) and (orientation: landscape) {
  .modal_subm_title {
    font-size: 0.9em;
  }
  .modal_subm_text_container {
    font-size: 0.9em;
  }
  .modal_subm_select_box {
    & h4 {
      font-size: 0.8em;
    }
  }
  .modal_subm_continue {
    font-size: 0.9em;
  }
  .tabs_btns_cont {
    & button {
      font-size: 0.8em;
    }
  }
  .tugo_tab_info {
    font-size: 0.8em;
  }

  .policy {
    margin-top: 10px;
    &_price {
      font-size: 1.5em;
      padding-bottom: 10px;
      width: 20%;
      text-align: center;
    }
    &__btn {
      font-size: 0.9em;
    }
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }
  .text_block {
    font-size: 0.8em;
  }

  .content {
    width: 100%;
    & h4 {
      font-size: 0.9em;
    }
  }
  .logo_container {
    min-height: 40px;
    width: 30%;
    & img {
      width: 100%;
    }
  }
  .policy_logo_mod {
    max-width: 21%;
    margin-bottom: 5px;
  }
  .modal_content {
    padding-right: 10px;
    &_title {
      font-size: 0.8em;
    }
    &_benefits {
      padding: 3px;
    }
    &_item {
      font-size: 0.8em;
    }
  }
}

@media (max-width: 580px) {
  .destination_modal_title {
    padding-right: 0;
  }

  .policy {
    margin-top: 15px;
  }
  .modal_subm_title {
    width: 80%;
    margin: 0 auto 5px;
    text-align: center;
    font-size: 1em;
  }
  .modal_subm_text_container {
    font-size: 1em;
  }
  .modal_subm_select_box {
    & h4 {
      font-size: 0.9em;
    }
  }
  .modal_subm_continue {
    font-size: 1em;
  }
  .tabs_btns_cont {
    & button {
      font-size: 0.9em;
    }
  }
  .tugo_tab_info {
    font-size: 0.9em;
  }
  .policy_logo_mod {
    max-width: 21%;
    margin-bottom: 10px;
  }
  .modal_content {
    padding-right: 15px;
    &_title {
      font-size: 0.9em;
    }
    &_benefits {
      padding: 5px;
    }
    &_item {
      font-size: 0.9em;
    }
  }
  .modal_content_benefit_tugo {
    width: 30%;
  }
}

@media (max-width: 340px) {
  .modal_subm_title {
    font-size: 0.9em;
  }
  .modal_subm_text_container {
    font-size: 0.9em;
  }
  .modal_subm_select_box {
    & h4 {
      font-size: 0.8em;
    }
  }
  .modal_subm_continue {
    font-size: 0.9em;
  }
  .tabs_btns_cont {
    & button {
      font-size: 0.8em;
    }
  }
  .tugo_tab_info {
    font-size: 0.8em;
  }

  .policy {
    margin-top: 25px;
    &_price {
      font-size: 1.5em;
      padding-bottom: 10px;
    }
    &__btn {
      font-size: 0.9em;
    }
  }
  .text_block {
    font-size: 0.8em;
  }

  .content {
    & h4 {
      font-size: 0.9em;
    }
  }
  .logo_container {
    min-height: 40px;
  }
  .policy_logo_mod {
    max-width: 21%;
    margin-bottom: 5px;
  }
  .modal_content {
    padding-right: 10px;
    &_title {
      font-size: 0.8em;
    }
    &_benefits {
      padding: 3px;
    }
    &_item {
      font-size: 0.8em;
    }
  }
}

.answers {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;

  & div {
    border-radius: 10px;
    margin-left: 2em;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 1.375em 1.7em;
    font-weight: 500;
  }
}
