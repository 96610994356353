.select {
  width: 100%;
  border-radius: 12px;
  background: rgba(54, 54, 54, 0.75);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vmin;
  font-size: 1.7vmin;
  position: relative;
  z-index: 9;
  cursor: pointer;
  transition: all 0.3s ease !important;
  font-weight: 500;
  &:hover {
    background: rgba(54, 54, 54, 1);
  }
  &_error {
    border: 4px solid red;
  }
  &_min {
    height: 5vmin;
    min-height: 40px;
    max-height: 50px;
    border-radius: 10px;
  }
}
.active {
  background: rgba(54, 54, 54, 1);
}
.select_val {
  width: 100%;
  padding: 0 10px 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  vertical-align: middle;
}

.option {
  cursor: pointer;
  text-align: center;
  padding: 0 1.8vmin;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 30px;
}
.option__container {
  position: absolute;
  padding: 10px 1px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(54, 54, 54, 1);
  border-radius: 12px;
  transition: all 0.3s ease;
  &_scr {
    overflow-y: auto;
    width: 100%;
    height: auto;
    // max-height: 200px;
    max-height: 22vh;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-button {
      background-color: #363636;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #999;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: #363636;
    }
    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: #666;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-corner {
      background-color: #999;
    }
    &::-webkit-resizer {
      background-color: #666;
    }
  }
}

.option__container_dropdown {
  position: absolute;
  padding: 10px 1px;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(54, 54, 54, 1);
  border-radius: 12px;
  transition: all 0.3s ease;
  &_scr {
    overflow-y: auto;
    width: 100%;
    height: auto;
    // max-height: 200px;
    max-height: 22vh;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-button {
      background-color: #363636;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: #999;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: #363636;
    }
    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: #666;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-corner {
      background-color: #999;
    }
    &::-webkit-resizer {
      background-color: #666;
    }
  }
}

.select_disabled {
  pointer-events: none;
  opacity: 0.6;
}

.arrow {
  position: absolute;
  max-width: 20px;
  width: 2vmin;
  max-height: 15px;
  height: 1.5vmin;
  right: 2%;
  bottom: 50%;
  transform: translate(0, 50%) rotate(180deg);
  background: url(./../../asset/arrow.svg) center center no-repeat;
  background-size: 100%;
  z-index: 10;
  transition: all 0.2s ease;
  &__activ {
    transform: translate(0, 50%) rotate(0deg);
  }
}


@media (max-width: 900px) and (min-width: 560px) and(orientation: landscape) {
  .select {
    width: 100%;
    border-radius: 6px;
    height: 11vmin;
    font-size: 3vmin;
    &_error {
      border: 2px solid red;
    }
  }
  .option__container {
    position: absolute;
    border-radius: 6px;
    &_scr {
      max-height: 40vmin;
    }
  }
}
@media (max-width: 560px) {
  .select {
    width: 100%;
    border-radius: 6px;
    height: 11vmin;
    font-size: 3vmin;
    &_error {
      border: 2px solid red;
    }
  }
  .option__container {
    position: absolute;
    border-radius: 6px;
    &_scr {
      max-height: 40vmin;
    }
  }
}
